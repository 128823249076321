.scene-list-module {
    flex: 0 0 200px; background-color: #333;
    >.header {
        display: flex; font-size: 14px;
        padding: 5px;
    }
    >.container {
        >.header {
            font-size: 12px;
            color: #fff;
            padding: 2px 15px;
        }
    }
}